import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'  
import { getNews } from '../Service/Api'
const News = () => { 
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getNews();
        setNewsData(data);
      } catch (error) {
        console.error("Failed to fetch news:", error);
        setNewsData([]); // Set to empty to ensure the empty state is handled
      }
    };
    fetchData();
  }, []);
  const emptyArray = [
      { title: "Stay Tuned For More Updates", description: "Stay Tuned For More Updates" },
      { title: "Stay Tuned For More Updates", description: "Stay Tuned For More Updates" },
      { title: "Stay Tuned For More Updates", description: "Stay Tuned For More Updates" }
  ];
  return (
    <>
      <div className="newsbg">
        <div className='title'> 
          <h2>Latest news</h2>
          
        <button className='button light'> <Link to="/News"><i className="bi bi-chevron-double-right"></i></Link></button>
        </div>
        <marquee onMouseOver={(event) => event.currentTarget.stop()} direction="up" onMouseOut={(event) => event.currentTarget.start()}>
          <ul className="newsboxlist">
          {newsData.length > 0 ?  newsData?.filter((item) =>  item.category === "News").map((item, index) => (   
             <li key={index}>
                <div className="newsbox">
                  <div className="newsdesc">
                    <h6>{item.title}</h6>
                    <p>{item.description}</p>
                    {item.url && <Link to={`${item.url}`} target="_blank"><button>Click Here</button></Link>}
                  </div>
                  {item.attachments.length> 0 && <Link to={`https://webapi.entab.info/api/image/${item.attachments[0]}`} target="_blank"><i className="bi bi-paperclip"></i></Link> }
                </div>
              </li>
              )) : 
          emptyArray.map((data, index) => (
             <li key={index}>
                <div className="newsbox">
                  <div className="newsdesc">
                  <h6>{data.title}</h6>
                  <p>{data.description}</p>
                  </div>
                  <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
                </div>
              </li>
              ))
            }
          </ul>
        </marquee>
      </div>
    </>
  )
}

export default News
