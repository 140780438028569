import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getMedia } from '../Service/Api';
import LazyLoad from 'react-lazyload';

const MediaCoverage = () => {
    useEffect(() => {
        document.title = 'MediaCoverage - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);

    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("All");
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [yearRanges, setYearRanges] = useState([]);
    const [availableMonths, setAvailableMonths] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const handleMonth = (month) => monthsOfYear[month - 1];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const galleryData = await getMedia();
                console.log("Fetched data:", galleryData);

                if (!galleryData || !Array.isArray(galleryData)) {
                    console.error("Invalid data format");
                    return;
                }

                setData(galleryData);

                // Extract unique years
                const uniqueYears = Array.from(
                    new Set(
                        galleryData
                            .map(item => item.date ? new Date(item.date).getFullYear() : null)
                            .filter(year => year !== null)
                    )
                ).sort((a, b) => b - a);

                setYearRanges(uniqueYears);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        let filtered = [...data];
        let monthsSet = new Set();

        if (selectedYear !== "All") {
            filtered = filtered.filter(item => {
                if (item.date) {
                    const year = new Date(item.date).getFullYear();
                    if (parseInt(selectedYear) === year) {
                        const month = new Date(item.date).getMonth() + 1;
                        monthsSet.add(month);
                        return true;
                    }
                }
                return false;
            });
        } else {
            filtered.forEach(item => {
                if (item.date) {
                    monthsSet.add(new Date(item.date).getMonth() + 1);
                }
            });
        }

        setAvailableMonths([...monthsSet].sort((a, b) => a - b));

        if (selectedMonth !== "All") {
            filtered = filtered.filter(item => {
                return item.date && (new Date(item.date).getMonth() + 1) === parseInt(selectedMonth);
            });
        }

        if (searchQuery) {
            filtered = filtered.filter(item =>
                item.title?.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        setFilteredData(filtered);
    }, [selectedYear, selectedMonth, searchQuery, data]);

    return (
        <>
            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link></li> 
                    <li>Media</li>
                    <li>Media Coverage</li>
                </ul>
            </div> 

            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Media Coverage</h1>
                        </div>
                    </div>

                    <div className="row tabs-dynamic">
                        <div className="col-md-3">
                            <div className="count-val">
                                <p>Total Count: {filterData.length}</p>
                            </div>
                        </div> 

                        <div className="col-md-3">
                            <div className="month-selection">
                                <select 
                                    id="yearSelection" 
                                    onChange={(e) => setSelectedYear(e.target.value)} 
                                    value={selectedYear}
                                >
                                    <option value="All">All Years</option>
                                    {yearRanges.map((yr, index) => (
                                        <option key={index} value={yr}>{yr}</option>
                                    ))}
                                </select>
                            </div>
                        </div>  

                        <div className="col-md-6">
                            <div className="searchBox">
                                <input 
                                    type="text" 
                                    id="myInput" 
                                    name="name" 
                                    autoComplete="off" 
                                    placeholder="Search Here.." 
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="clr"></div>

                    <div className="row media-cover">
                        {filterData.length > 0 ? (
                            filterData.map((item, index) => (
                                <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth magzine">
                                    <Link to={`${item.url}`} target="_blank">
                                        <LazyLoad>
                                            <img 
                                                src={`https://webapi.entab.info/api/image/${item?.attachments?.[0]}`} 
                                                className="img-fluid" 
                                                alt="Media Coverage" 
                                            />
                                        </LazyLoad>
                                        <h4>{item.title}</h4>
                                    </Link> 
                                </div>
                            ))
                        ) : (
                            Array(4).fill().map((_, index) => (
                                <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth magzine"> 
                                    <Link to="#" target="_blank">
                                        <LazyLoad>
                                            <img 
                                                src="https://webapi.entab.info/api/image/MGMB/public/Images/magzine.png" 
                                                className="img-fluid" 
                                                alt="No Media Available" 
                                            />
                                        </LazyLoad>
                                        <h4>Title</h4>
                                    </Link>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default MediaCoverage;
